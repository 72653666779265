<template lang="pug">
  .featured-services-v2.md-ml-200m.md-mt-75m(v-if="servicesPopulated.length > 0")
    SimpleCarouselSSR.slick-dots-modifier.mt-50m.mb-100m(v-if="showMobileFeaturedServices && servicesPopulated.length > 1" :options="carouselOptions")
      .pl-50m.pr-50m(v-for="(service) in servicesPopulated" :key="service.service.code")
        .service-card.service-card--mobile
          .featured-pill
            span.upper.bold
              | {{ pillText }}
          .service-image
            .a-box-xs-2x1
              .a-box-content
                ImgBox.img-box-modifier(:media-obj="service.image")
          .service-text-section
            h3.service-title.f-primary.narrow-letter-spacing.bold.xs-f-small
              | {{ service.service.name }}
            p.service-description.f-primary.narrow-letter-spacing.xs-f-xsmall
              | {{ service.description }}
            MemberNonMemberPrice(
              :member-price="service.memberPrice"
              :regular-price="service.service.price"
              :always-inline="true"
            )
          button.service-button.btn-reset.f-primary.upper.bold.narrow-letter-spacing.full-width(@click="selectService(service.service, service.includesColorWonder)")
            | {{ linkText }}
            MrIcon(name="arrow-right")
    .service-card.mb-150m(v-else v-for="(service) in servicesPopulated" :key="service.service.code")
      .featured-pill
        span.upper.bold
          | {{ pillText }}
      .service-image
        .a-box-xs-2x1
          .a-box-content
            ImgBox.img-box-modifier(:media-obj="service.image")
      .service-text-section
        h3.service-title.f-primary.narrow-letter-spacing.bold
          | {{ service.service.name }}
        p.service-description.f-primary.narrow-letter-spacing
          | {{ service.description }}
        MemberNonMemberPrice(
          :member-price="service.memberPrice"
          :regular-price="service.service.price"
          :always-inline="true"
        )
      button.service-button.btn-reset.f-primary.upper.bold.narrow-letter-spacing.full-width(@click="selectService(service.service, service.includesColorWonder)")
        | {{ linkText }}
        MrIcon(name="arrow-right")


</template>

<script>
  import { mapActions, mapGetters, mapState } from "vuex";
  import MrIcon from '@components/MrIcon';
  import ImgBox from '@components/imgBox';
  import MemberNonMemberPrice from '@components/HairColorBarBooking/ServiceBoxV2/MemberNonMemberPrice';
  import SimpleCarouselSSR from '@components/SimpleCarouselSSR';

  export default {
    name: "FeaturedServicesV2",

    components: {
      MrIcon,
      ImgBox,
      MemberNonMemberPrice,
      SimpleCarouselSSR
    },

    props: {
      services: {
        type: Array,
        default: () => []
      },
      pillText: {
        type: String,
        default: ""
      },
      linkText: {
        type: String,
        default: ""
      },
    },

    computed: {
      ...mapGetters('hairColorBarBooking', [
        'servicesOfferedDictionary',
        'servicesIncludingAddons'
      ]),

      ...mapState('global', [
        'windowData',
      ]),

      ...mapState('hairColorBarBooking', ['location']),

      showMobileFeaturedServices() {
        return this.breakpoint === 'tablet' || this.breakpoint === 'mobile';
      },

      breakpoint() {
        return this.windowData.breakpoint;
      },

      carouselOptions() {
        return {
          accessibility: true,
          slidesToShow: 1,
          infinite: true,
          dots: true,
          arrows: false,
          lazyLoad: 'progressive',
          autoplay: false,
        };
      },

      servicesPopulated() {
        if (!this.services?.length || !this.servicesOfferedDictionary || !Object.keys(this.servicesOfferedDictionary).length) {
          return [];
        }

        return this.services.map(service => {
          const serviceFound = this.servicesOfferedDictionary[service.service.code] || {};
          return {
            ...service,
            memberPrice: this.memberPrice(serviceFound),
            service: serviceFound
          };
        });
      }
    },

    methods: {
      ...mapActions('hairColorBarBooking', ['setSelectedService', 'openServiceMoreDetailsModal']),

      selectService(service, includesColorWonder) {
        if (service) {
          this.trackMREvent("Services redesign 2024 - featured service selected", { serviceCode: service.code });
          this.setSelectedService({...service, includesColorWonder});
          this.$router.push({
            name: 'booking-calendar',
            params: {
              code: this.location.code,
            },
          });
        }
      },

      memberPrice(service) {
        let { forMembersPrice, discountPrice, price } = service;


        if (forMembersPrice >= 0 && forMembersPrice != price) {
          return forMembersPrice;
        }

        if (discountPrice >= 0 && discountPrice != price) {
          return discountPrice;
        }

        return price;
      },
    }
  };
</script>

<style lang="stylus">
  .slick-dots-modifier
    ul
      margin-bottom 1em

      li
        button
          &::before
            font-size 30px
</style>

<style lang="stylus" scoped>
  .featured-services-v2
    .service-card
      position relative
      text-align left
      background-color #EDEDF1
      border-radius 1em
      padding 0.2em

      .service-image
        border-top-left-radius 1em
        border-top-right-radius 1em

        .img-box-modifier
          border-top-left-radius 1em
          border-top-right-radius 1em

      .featured-pill
        font-size 0.7em
        z-index 1
        position absolute
        top 0
        left 0
        margin 1em

        span
          background-color white
          border-radius 1em
          color color-mr-purple
          padding 0 0.5em

      .service-text-section
        background-color white;
        border-bottom-left-radius 1em
        border-bottom-right-radius 1em
        padding 1.25em

        .service-title
          margin-bottom 0.62em

        .service-description
          border-bottom 1px solid #EDEDF1
          margin-bottom 0.62em
          padding-bottom 0.62em

      .service-button
        text-align center
        margin 0.38em 0
        color color-mr-purple!important

        svg
          margin-left 0.2em
          transform: rotate(-45deg)
</style>
