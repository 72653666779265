<template lang="pug">
   .simple-carousel
      button.left-nav(v-if="showArrows" type="button" @click="prev" :class="{'floating-arrows': theme === 'floating-arrows'}")
        slot(name="left-nav-content")
          span.icon-caret-left.text-color-2.xs-f-large.max-at-tweak.non-focusable(tabindex="-1")
            span.ada-tooltip previous

      .carousel(:class="{ 'arrows-padding': showArrows }")
        VueSlickCarousel(ref="slick" v-bind="carouselOptions" @afterChange="$emit('afterChange', Array.from(arguments)[0])" @beforeChange="$emit('beforeChange', Array.from(arguments)[0])" @init="$emit('init')"  @reInit="$emit('reInit')" @lazyLoad="$emit('lazyLoad')")
          slot

      button.right-nav(v-if="showArrows" type="button" @click="next" :class="{'floating-arrows': theme === 'floating-arrows'}")
        slot(name="right-nav-content")
          span.icon-caret-right.text-color-2.xs-f-large.max-at-tweak.non-focusable(tabindex="-1")
            span.ada-tooltip next

</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';

  export default {
    name: 'SimpleCarouselSSR',

    components: {
      VueSlickCarousel,
    },

    inject: ['getObjProperty'],

    props: {
      options: {
        type: Object,
        default: () => ({}),
      },
      theme: {
        type: String,
        default: 'default',
      }
    },

    data: () => ({
      isMounted: false,
      slick: null,
    }),

    computed: {
      carouselOptions() {
        const options = {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          dots: false,
          arrows: false,
          lazyLoad: 'progressive',
          ...this.options,
        };

        if (!this.isMounted) {
          options.responsive = undefined;
        }

        return options;
      },

      breakpoint() {
        if (!this.slick) {
          return;
        }

        if (!this.slick.breakpoint) {
          return;
        }

        return this.slick.responsive.find(({ breakpoint }) => breakpoint == this.slick.breakpoint);
      },

      showArrows() {
        if (!this.breakpoint) {
          return !!this.carouselOptions.arrows;
        }
        return !!this.breakpoint.settings.arrows;
      },
    },

    watch: {
      breakpoint() {
        this.$emit('breakpoint', this.breakpoint);
      },
    },

    mounted() {
      this.isMounted = true;
      this.slick = this.$refs.slick;
    },

    methods: {
      prev() {
        this.$refs.slick.prev();
      },

      next() {
        this.$refs.slick.next();
      },

      currentSlide() {
        return this.$refs.slick.slickCurrentSlide();
      },

      goTo(aNumber, dontAnimate) {
        this.$refs.slick.goTo(aNumber, dontAnimate === true);
      },

      pause() {
        this.$refs.slick.pause();
      },

      play() {
        this.$refs.slick.play();
      },
    },
  };
</script>

<style lang="stylus">
  .simple-carousel
    display block
    position relative
    width 100%
    .slick-arrow
      display none !important
    .carousel
      width 100%
      max-height 100%
      .slick-slider
        margin-bottom 0px
        .slide
          padding 0px 5px
    .left-nav
    .right-nav
      height 100%
      position absolute
      background-color transparent
      border 0px
      top 0
    .left-nav:not(.floating-arrows)
      left -45px
    .right-nav:not(.floating-arrows)
      right -45px
    .left-nav.floating-arrows
      left 0
      z-index 10
      background linear-gradient(to left, transparent 0%, white 50%, white 100%)
    .right-nav.floating-arrows
      right 0
      z-index 10
      background linear-gradient(to right, transparent 0%, white 50%, white 100%)
    @media mq-mobile
      .carousel
        &.arrows-padding
          padding 0px 10%
      .left-nav
        left -2%
      .right-nav
        right -2%
</style>
