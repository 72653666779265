<template lang="pug">
  .price-member-nonmember.text-color-3.xs-f-xsmall.sm-f-small.md-f-xsmall.max-at-tweak(:class="{ allowStaked: !alwaysInline }")
    span Non-Members&nbsp;
      span.text-color-1 {{ $currencyShort(regularPrice) }}
    .point.xs-ml-30m.md-ml-50m.xs-mr-30m.md-mr-50m
    span {{ membershipText }}&nbsp;
      span.cta-color-1 {{ $currencyShort(memberPrice) }}
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    name: "MemberNonMemberPrice",
    props: {
      memberPrice: {
        default: null
      },
      regularPrice: {
        default: null
      },
      alwaysInline: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters('customer', ['customerHasHCBMemberships']),
      membershipText() {
        return this.customerHasHCBMemberships ? 'Your Price' : 'Members';
      }
    }
  };
</script>
<style lang="stylus" scoped>
  .price-member-nonmember
    display flex
    align-items center
    text-transform none
    flex-wrap wrap
    width 100%

    .point
      background-color #AC57A3
      border-radius 50%
      width 0.3em
      height 0.3em

    &.allowStaked

      @media mq-mobile
        flex-direction column
        align-items flex-start

        .point
          display none


</style>
