<template lang="pug">
.root-perfection-just-there.container
  .row.xs-f-small.max-at-tweak
    .fixed-width-tweak-upper
      .details-inner.xs-12
        .row.xs-f-small.max-at-tweak
          .inner-wrap.xs-12
            h2.title-element.f-secondary.text-center.xs-f-xgrande.max-at-tweak(tabindex="0") {{title}}

        .row.xs-f-small.max-at-tweak
          .middle-inner-wrap.xs-12
            focus-lock.modal-body(v-show="modalIsShowing" ref="modalBody" role="dialog" aria-modal="true")
              button.btn-close.transparent-btn.xs-f-large.max-at-tweak(type="button" alt="Close modal" aria-label="Close modal" data-mr-ass="close-modal" @click.prevent="onModalHide")
                span.icon-x

              .video-wrap.xs-12.md-6(v-if="modalIsShowing")
                video.fullpageVideo(height="auto" width="100%" controls preload="metadata" :alt="longVideo.alt_text" autoplay webkit-playsinline playsinline)
                  source(:src="`${longVideo.url}?fm=webm`" type='video/webm')
                  source(:src="`${longVideo.url}?fm=mp4`" type='video/mp4')

            .video-wrap.xs-12.md-6(v-if="!modalIsShowing")
              video.full-width(height="auto" width="100%" controls preload="metadata" :alt="shortVideo.alt_text" autoplay muted webkit-playsinline playsinline)
                source(:src="`${shortVideo.url}?fm=webm`" type='video/webm')
                source(:src="`${shortVideo.url}?fm=mp4`" type='video/mp4')

            .details-wrap.xs-12.md-6
              .list-wrap.xs-f-small.md-f-medium.xs-12
                ol.in-box-list.max-at-tweak(role="list" tabindex="0")
                  li(v-for="(stepText, index) in steps" role="listitem")
                    span.bold Step {{ index + 1 }}:&nbsp;
                    span {{stepText}}

                p.additional-text.max-at-tweak
                  a.underline(tabindex="0" role="button" @click.prevent="onModalShow") Click here
                  span &nbsp;to watch the full application tutorial.
</template>
<script>
  import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

  export default {
    name: "RPJustThere",

    props: {
      title: {
        type: String,
        default: ''
      },
      longVideo: {
        type: Object,
        default() {
          return {};
        },
      },
      shortVideo: {
        type: Object,
        default() {
          return {};
        },
      },
      steps: {
        type: Array,
        default() {
          return [];
        },
      },
    },

    data() {
      return {
        modalIsShowing: false
      };
    },

    destroyed() {
      clearAllBodyScrollLocks();
    },

    methods: {
      onModalHide() {
        this.modalIsShowing = false;

        enableBodyScroll(this.$refs.modalBody);
      },

      onModalShow() {
        this.modalIsShowing = true;

        disableBodyScroll(this.$refs.modalBody, {
          reserveScrollBarGap: true,
          allowTouchMove: el => {
            while (el && el !== document.body) {
              if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                return true;
              }
              el = el.parentNode;
            }
          }
        });
      },
    },

  };
</script>
<style lang="stylus">
  .root-perfection-just-there
    background-color ui-color-4
    overflow hidden

    .modal-body
      position fixed
      top 0
      bottom 0
      left 0
      right 0
      z-index 1000000

      div[data-lock]
        height 100%
        background-color black

      .btn-close
        color white
        position fixed
        right 0
        margin 10px
        cursor pointer
        z-index 10

      .video-wrap
        width 100%
        height 100%
        top 50%
        padding 0 !important

        .fullpageVideo
          transform translateY(-50%)
          max-width 100%
          max-height 100%
          padding 0

    .middle-inner-wrap
      display inline-flex
      flex-direction column
      justify-items center
      align-items center

      @media mq-tablet-plus
        flex-direction row-reverse
        flex-direction row

        .in-box-list
          margin-block-start 0

      .video-wrap
        display flex
        align-items center
        top 0%

        .fullpageVideo
          transform unset

    .middle-inner-wrap
    .inner-wrap
      padding-top 14px
      padding-bottom 42px

      @media mq-tablet-less
        padding-bottom 0px

      .title-element
        margin-top 32px
        margin-bottom 32px

        @media mq-tablet-plus
          margin-bottom 0px

      .video-wrap
        padding 0px 17px

        @media mq-tablet-plus
          padding 0px 27px

      .details-wrap
        padding 0px 17px

        .list-wrap
          display flex
          flex-wrap wrap
          justify-content center

          @media mq-tablet-less
            margin-top 15px
            margin-bottom 30px

          .in-box-list
            list-style none

          .additional-text
            margin-top 30px

        @media mq-tablet-plus
          float left
          padding 0px 27px
</style>