<template lang="pug">
  .maximizable-video.container
    .small-video-container.row.xs-12.max-at-tweak(v-if="!modalIsShowing")
      .video-inner-wrap.xs-12(v-if="shortVideo")
        .video-wrap.xs-12
          video.small-video.full-width(height="auto" width="100%" preload="metadata" :alt="shortVideo.alt_text" autoplay loop muted webkit-playsinline playsinline)
            source(:src="`${shortVideo.url}?fm=webm`" type='video/webm')
            source(:src="`${shortVideo.url}?fm=mp4`" type='video/mp4')

          .floating-button-wrap.xs-hide(v-if="cta && cta.url")
            button.upper.text-center.mr-btn.xs-f-xxsmall.max-at-tweak(@click="trackCTA(cta.url, 'video-cta-carousel-banner')") {{ cta.text }}

          .details-wrap.xs-12.text-center(v-if="longVideo")
            p.additional-text.max-at-tweak
              a.underline(tabindex="0" @click.prevent="onModalShow") Click here
              span &nbsp;to watch the full application tutorial.
          .mobile-cta-wrap.sm-hide.md-hide.lg-hide(v-if="cta.url")
            button.upper.text-center.mr-btn.btn-block.xs-f-xxsmall.max-at-tweak(@click="trackCTA(cta.url, 'video-cta-carousel-banner')") {{cta.text}}

    .video-full-screen-container.xs-12(v-show="modalIsShowing && longVideo")
      transition(name="modal-background-fade")
        .modal-backdrop.bg-black-a80(@click.self="backgroundClick")

      transition(name="modal-body-fade")
        focus-lock.modal(ref="modalBody" role="dialog" aria-modal="true" tabindex="0" body-scroll-lock-ignore)
          button.btn-close.transparent-btn.xs-f-large.max-at-tweak(type="button" alt="Close modal" aria-label="Close modal" data-mr-ass="close-modal" @click.prevent="onModalHide")
            span.icon-x

          .video-wrap.xs-12(v-if="modalIsShowing")
            video.fullpageVideo(height="auto" width="100%" controls preload="metadata" :alt="longVideo.alt_text" autoplay webkit-playsinline playsinline)
              source(:src="`${longVideo.url}?fm=webm`" type='video/webm')
              source(:src="`${longVideo.url}?fm=mp4`" type='video/mp4')
</template>
<script>
  import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

  export default {
    name: "MaximizableVideo",

    props: {
      shortVideo: {
        type: Object,
        required: true,
      },
      longVideo: {
        type: Object,
        required: true,
      },
      cta: {
        type: Object,
        default() {
          return {};
        },
      },
    },

    data() {
      return {
        modalIsShowing: false,
      };
    },

    destroyed() {
      clearAllBodyScrollLocks();
    },

    methods: {
      onModalHide() {
        this.modalIsShowing = false;

        enableBodyScroll(this.$refs.modalBody);
      },

      onModalShow() {
        this.modalIsShowing = true;

        disableBodyScroll(this.$refs.modalBody, {
          reserveScrollBarGap: true,
          allowTouchMove: el => {
            while (el && el !== document.body) {
              if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                return true;
              }
              el = el.parentNode;
            }
          }
        });
      },
    },
  };
</script>
<style lang="stylus">
  .maximizable-video
    overflow hidden

    .small-video-container
      float none

    .video-full-screen-container
      overflow hidden

    .modal
      position fixed
      top 0
      bottom 0
      left 0
      right 0
      z-index 1000000

      div[data-lock]
        height 100%
        background-color black
        display flex
        align-items center

      .btn-close
        color white
        position fixed
        top 0
        right 0
        margin 0.71em
        cursor pointer
        z-index 10


    .video-inner-wrap
      position relative
      max-height 30.22em
      display flex
      align-items center
      justify-content center
      height 100%
      background-color ui-color-5
      margin-bottom -0.18em

      @media mq-mobile
        margin-bottom 0em

      @media mq-tablet
        margin-bottom -0.21em

      @media mq-desktop-md
        margin-bottom -0.21em

      .floating-button-wrap
        position absolute
        display block
        right 2em
        bottom 4em
        box-shadow 0 0.29em 0.29em 0 rgba(0 0 0, 0.23)

        @media mq-mobile
          bottom 1em

        @media mq-tablet
          bottom 2em

        @media mq-desktop-md
          bottom 2em

      .details-wrap
        margin-bottom 0.71em
        margin-top 0.21em

      .mobile-cta-wrap
        background-color white
        padding 1em 2em

      .video-wrap
        height 100%
        top 50%
        padding 0 !important

        @media mq-mobile
          width 100%

        .fullpageVideo
          transform translateY(-50%)
          max-width 100%
          max-height 100%
          padding 0

        .small-video
          @media mq-mobile
            margin-bottom -0.2em
</style>