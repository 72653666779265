import { render, staticRenderFns } from "./FeaturedServicesV2.vue?vue&type=template&id=7cd10465&scoped=true&lang=pug"
import script from "./FeaturedServicesV2.vue?vue&type=script&lang=js"
export * from "./FeaturedServicesV2.vue?vue&type=script&lang=js"
import style0 from "./FeaturedServicesV2.vue?vue&type=style&index=0&id=7cd10465&prod&lang=stylus"
import style1 from "./FeaturedServicesV2.vue?vue&type=style&index=1&id=7cd10465&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd10465",
  null
  
)

export default component.exports